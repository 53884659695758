<template>
  <div class="login-content d-flex flex-column p-10 animate__animated animate__fadeIn">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <!--begin::Logo-->
      <div v-if="$vuetify.breakpoint.mobile" class="text-center mb-10">
        <a href="#" class="flex-column-auto">
          <img src="/media/logos/logo-with-name-black-vertical.png" width="70%" />
        </a>
      </div>

      <div class="pb-10 text-center">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h2-lg mb-10">
          <lottie-player
            v-if="status == true"
            autoplay
            mode="normal"
            src="https://assets3.lottiefiles.com/packages/lf20_y2hxPc.json"
          ></lottie-player>

          <lottie-player
            v-else-if="status == false"
            autoplay
            loop
            mode="normal"
            src="https://assets6.lottiefiles.com/private_files/lf30_glnkkfua.json"
          ></lottie-player>
          {{ message }}
        </h3>
        <div>
          <router-link
            custom
            class="btn btn-block btn-xs btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
            :to="{ name: 'login' }"
          >
            Realizar Login
          </router-link>
        </div>
      </div>
    </div>
    <!--end::Signin-->
  </div>
</template>

<style>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
export default {
  name: 'EmailVerifyCheck',

  data() {
    return {
      status: null,
      message: null
    };
  },
  async created() {
    await this.$store
      .dispatch('REGISTER_VERIFY_EMAIL', this.$route.query.verifyLink)
      .then((response) => {
        this.status = true;
        this.message = 'Seu endereço de email foi validado com sucesso!';
      })
      .catch((error) => {
        this.status = false;
        this.message = error;
      });

    this.$store.dispatch('LOGOUT');
  },
  methods: {}
};
</script>
